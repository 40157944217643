import BuildPlanService from '../../../../../services/plan/BuildPlanService';
import iBuild from '../../../../../types/build/iBuild';
import ComponentWithPreloadData from '../../../../common/ComponentWithPreloadData';
import iBuildPlan from '../../../../../types/plan/iBuildPlan';
import PlanRevisionService from '../../../../../services/plan/PlanRevisionService';
import BuildDetailsPlansChanges from './BuildDetailsPlansChanges';

type iBuildDetailsPlanChanges = {
  build: iBuild;
  isDisabled?: boolean;
  onBuildUpdated?: (updated: iBuild) => void;
  allowEdit: boolean;
  allowDelete: boolean;
};

const BuildDetailsPlansChangesWrapper = ({
  build,
  onBuildUpdated,
  ...props
}: iBuildDetailsPlanChanges) => {
  return (
    <ComponentWithPreloadData<iBuildPlan>
      emptyStateProps={{
        description: `Couldn't find requested Build Plan with id: ${build.id}.`,
      }}
      getDataFn={async () => {
        const { data } = await BuildPlanService.getAll({
          where: JSON.stringify({ isActive: true, buildId: build.id }),
          include: 'InitRevision',
          currentPage: 1,
          perPage: 10,
        });
        if (data.length === 1) return data[0];
        const { data: planRevisions } = await PlanRevisionService.getAll({
          where: JSON.stringify({ isActive: true }),
          sort: `sort:ASC`,
          currentPage: 1,
          perPage: 10,
        });
        if (planRevisions.length === 0)
          throw new Error("can't find any plan revisions");

        const buildPlan: iBuildPlan = await BuildPlanService.create({
          buildId: build.id,
          revisionId: planRevisions[0].id,
        });
        return { ...buildPlan, InitRevision: planRevisions[0] };
      }}
    >
      {({ data: buildPlan }) => (
        <BuildDetailsPlansChanges
          buildPlan={buildPlan}
          {...props}
          onSaved={() => {
            onBuildUpdated && onBuildUpdated(build);
          }}
          onDeleted={() => {
            onBuildUpdated && onBuildUpdated(build);
          }}
        />
      )}
    </ComponentWithPreloadData>
  );
};

export default BuildDetailsPlansChangesWrapper;
