import iBaseType from '../iBaseType';
import iBuild from '../build/iBuild';
import iAttribute from '../attribute/iAttribute';

export const MESSAGE_STATUS_NEW = 'NEW';
export const MESSAGE_STATUS_WIP = 'PROCESSING';
export const MESSAGE_STATUS_SUCCESS = 'SUCCESS';
export const MESSAGE_STATUS_FAILED = 'FAILED';

type iMessage = iBaseType & {
  type: string;
  status?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request?: any | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response?: any | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any | null;
  requestCheckSum?: string;
  buildId?: string | null;
  attributeId?: string | null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Build?: iBuild | null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Attribute?: iAttribute | null;
};

export default iMessage;
