import React, { useEffect, useState } from 'react';
import EntityNames from '../../../../../helpers/EntityNames';
import { AssetTypes } from '../../../../../types/asset/iAsset';
import AttachmentService from '../../../../../services/attachment/AttachmentService';
import Toaster from '../../../../common/Toaster';
import PopupBtn from '../../../../common/PopupBtn';
import AttachmentsTable from '../../../../asset/AttachmentsTable';
import { SelectiveColKeys } from '../../../../../services/LocalStorageService';
import Heading from '../../../../frameWork/Heading';
import { getFooterWithBtns } from '../../../../common/PopupModal';

type iState = {
  isLoading: boolean;
  total?: number;
  version: number;
};
const initialState: iState = {
  isLoading: false,
  version: 1,
};

type iPlansPopupBtn = {
  entityName: EntityNames;
  entityId: string;
  types?: AssetTypes[];
  postUploadPlan?: () => void;
  onClose?: () => void;
  title?: string;
  testId?: string;
};
const PlansPopupBtn = ({
  entityId,
  entityName,
  types,
  postUploadPlan,
  onClose,
  title,
  testId,
}: iPlansPopupBtn) => {
  const [state, setState] = useState(initialState);
  const { version } = state;
  useEffect(() => {
    let isCancelled = false;
    const loadTableData = async () => {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      try {
        const { total } = await AttachmentService.getAll({
          where: JSON.stringify({
            isActive: true,
            entityName,
            entityId,
            ...(types && types.length > 0 ? { type: types } : {}),
          }),
          currentPage: 1,
          perPage: 1,
        });
        if (isCancelled) return;
        setState((preState) => ({
          ...preState,
          total,
          isLoading: false,
        }));
      } catch (error) {
        if (isCancelled) return;
        Toaster.showApiError(error);
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
    };
    loadTableData();
    return () => {
      isCancelled = true;
    };
  }, [entityId, entityName, version]);

  return (
    <PopupBtn
      testId={`${testId || ''}-popup-btn`}
      isLoading={state.isLoading}
      titleId={`${testId}-titleId`}
      btnTxt={`${state.total || 0} File(s)`}
      onClose={() => {
        setState({ ...state, version: state.version + 1 });
        onClose && onClose();
      }}
      modalProps={(setShowingModal) => ({
        width: 'large',
        testId: `${testId}-popup`,
        title: title || 'Received Plans',
        shouldScrollInViewport: true,
        body: (
          <AttachmentsTable
            entityName={entityName}
            entityId={entityId}
            types={types}
            allowDelete
            allowCreate
            selectiveColumnKey={SelectiveColKeys.BUILD_PLAN_REQUEST_LIST}
            headerProps={{
              children: <Heading size={'small'}>Upload</Heading>,
              className: 'no-bottom-margin',
            }}
            onUploaded={postUploadPlan}
            defaultShowingColumns={[]}
          />
        ),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            testId: `delete-confirm-popup-cancelBtn-PlansPopupBtn`,
            onClick: () => {
              setShowingModal(false);
              setState({ ...state, version: state.version + 1 });
            },
            btnText: 'Close',
          },
        }),
      })}
    />
  );
};

export default PlansPopupBtn;
