import React, { useEffect, useState } from 'react';
import { iEntityFormField, iUseAsForm } from '../../../../form/EntityEditPanel';
import AttachmentsTable from '../../../../asset/AttachmentsTable';
import EntityNames from '../../../../../helpers/EntityNames';
import { AssetTypes } from '../../../../../types/asset/iAsset';
import { SelectiveColKeys } from '../../../../../services/LocalStorageService';
import Heading from '../../../../frameWork/Heading';
import SpinWhenLoading from '../../../../warehouseLocation/SpinWhenLoading';

const initialState: iState = {
  isLoading: true,
};
type iState = {
  isLoading: boolean;
  entityId?: string;
};
const PlansAttachmentWrapper = ({
  fProps,
  useAsForm,
  selectiveColumnKey,
}: {
  fProps: Omit<iEntityFormField, 'renderComponent' | 'isValid'>;
  useAsForm: iUseAsForm;
  selectiveColumnKey?: SelectiveColKeys;
}) => {
  const { fieldName, value } = fProps;
  const [state, setState] = useState(initialState);
  useEffect(() => {
    setState({
      isLoading: false,
      entityId: crypto.randomUUID(),
    });
  }, []);
  if (state.isLoading) return <SpinWhenLoading />;
  return (
    <AttachmentsTable
      entityName={EntityNames.Temporary}
      entityId={state.entityId || ''}
      types={[AssetTypes.BUILD_PLAN_CHANGE_SENT_PLANS]}
      defaultShowingColumns={[]}
      allowDelete
      allowCreate
      selectiveColumnKey={selectiveColumnKey}
      headerProps={{
        children: <Heading size={'xsmall'}>Sent Plans</Heading>,
        className: 'no-bottom-margin',
      }}
      onUploaded={(attachments) => {
        const arrayValue: Array<string> = JSON.parse(value as string);
        arrayValue.push(...attachments.map((a) => a.id));
        useAsForm.onFieldChange(fieldName, JSON.stringify(arrayValue));
      }}
      onDeleted={(attachments) => {
        const attachmentsIds = attachments.map((a) => a.id);
        const arrayValue: Array<string> = JSON.parse(value as string);
        useAsForm.onFieldChange(
          fieldName,
          JSON.stringify(
            arrayValue.filter((id) => !attachmentsIds.includes(id)),
          ),
        );
      }}
    />
  );
};

export default PlansAttachmentWrapper;
