import styled from 'styled-components';
import TextField from '../../frameWork/TextField';
import { getErrorProps, iErrorMap } from '../../form/FormError';
import Toggle from '../../frameWork/Toggle';
import RichTextEditor from '../../frameWork/RichTextEditor';
import AttachmentsTable from '../../asset/AttachmentsTable';
import EntityNames from '../../../helpers/EntityNames';
import { AssetTypes } from '../../../types/asset/iAsset';
import { SelectiveColKeys } from '../../../services/LocalStorageService';
import Heading from '../../frameWork/Heading';
import * as _ from 'lodash';
import Flex from '../../frameWork/Flex';
import { getFooterWithBtns, iGetDefaultBtn } from '../../common/PopupModal';
import Icons from '../../frameWork/Icons';
import React, { useEffect, useState } from 'react';
import iAttachment from '../../../types/asset/iAttachment';
import MessageService from '../../../services/message/MessageService';
import Toaster, { TOAST_TYPE_SUCCESS } from '../../common/Toaster';
import AttachmentService from '../../../services/attachment/AttachmentService';
import iMessage from '../../../types/message/iMessage';
import MathHelper from '../../../helpers/MathHelper';

const Wrapper = styled.div``;
type iEmailSendingPanel = {
  testId?: string;
  className?: string;
  buildId?: string;
  attributeId?: string;
  onSent?: (sent: iMessage) => void;
  onCancel?: () => void;
  actionBtnProps?: Omit<iGetDefaultBtn, 'onClick'>;
  cancelBtnProps?: Omit<iGetDefaultBtn, 'onClick'>;
  forceResetForm?: number;
};
const EmailSendingPanel = ({
  testId,
  className,
  buildId,
  attributeId,
  onSent,
  onCancel,
  actionBtnProps,
  cancelBtnProps,
  forceResetForm = 1,
}: iEmailSendingPanel) => {
  const ComponentName = 'EmailSendingPanel';
  const testIdStr = `${testId || ''}-${ComponentName}`;

  const [initEntityId, setInitEntityId] = useState(crypto.randomUUID());
  const [isSending, setIsSending] = useState(false);
  const [recipients, setRecipients] = useState<string[]>([]);
  const [cc, setCC] = useState<string[]>([]);
  const [bcc, setBCC] = useState<string[]>([]);
  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [showCCDiv, setShowCCDiv] = useState(false);
  const [attachments, setAttachments] = useState<iAttachment[]>([]);
  const [errorMap, setErrorMap] = useState<iErrorMap | null>(null);
  const [resetForm, setResetForm] = useState(forceResetForm);

  useEffect(() => {
    setIsSending(false);
    setErrorMap(null);
    setRecipients([]);
    setCC([]);
    setBCC([]);
    setBody('');
    setSubject('');
    setAttachments([]);
    setInitEntityId(crypto.randomUUID());
  }, [resetForm, forceResetForm]);

  const preCheck = () => {
    const errors: iErrorMap = {};
    const tos: string[] = recipients
      .map((recipient) => recipient.trim())
      .filter((recipient) => recipient !== '');
    if (tos.length === 0) {
      errors.recipients = 'Recipients is required.';
    }
    const subjectStr = subject.trim();
    if (subjectStr === '') {
      errors.subject = 'Subject is required.';
    }

    const bodyStr = body.trim();
    if (bodyStr === '') {
      errors.body = 'Body is required.';
    }

    setErrorMap(errors);
    return Object.keys(errors).length === 0;
  };

  const doSend = async () => {
    if (!preCheck()) {
      return;
    }
    try {
      setIsSending(true);
      const newMessage = await MessageService.sendEmail({
        to: recipients,
        subject,
        body,
        ...(cc.length > 0 && { cc }),
        ...(bcc.length > 0 && { bcc }),
        ...(attachments.length > 0 && {
          assetIds: attachments.map((attachment) => attachment.assetId),
        }),
        ...(buildId ? { buildId: buildId } : {}),
        ...(attributeId ? { attributeId: attributeId } : {}),
      });
      Toaster.showToast(
        'Email queued, it will be sent shortly.',
        TOAST_TYPE_SUCCESS,
      );

      // update the attachments' entityId
      await Promise.all(
        attachments.map((attachment) =>
          AttachmentService.update(attachment.id, {
            entityId: newMessage.id,
          }),
        ),
      );

      setResetForm(MathHelper.add(resetForm, 1));
      setIsSending(false);
      if (onSent) {
        onSent(newMessage);
      }
    } catch (err) {
      Toaster.showApiError(err);
      setIsSending(false);
    }
  };

  const getCCDiv = () => {
    if (!showCCDiv) {
      return null;
    }
    return (
      <>
        <TextField
          isRequired
          value={cc.join('')}
          label={'CC'}
          onChange={(event) => {
            setCC(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              `${event.target.value || ''}`
                .trim()
                .split(';')
                .map((emailAddress) => emailAddress.trim()),
            );
          }}
          helperMsg={'use ; as separator to have multiple.'}
        />
        <TextField
          isRequired
          value={bcc.join('')}
          label={'BCC'}
          onChange={(event) => {
            setBCC(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              `${event.target.value || ''}`
                .trim()
                .split(';')
                .map((emailAddress) => emailAddress.trim()),
            );
          }}
          helperMsg={'use ; as separator to have multiple.'}
        />
      </>
    );
  };

  return (
    <Wrapper
      data-test-id={testIdStr}
      className={`${ComponentName} ${className || ''}`}
    >
      <div>
        <TextField
          isRequired
          value={recipients.join('')}
          label={'Recipients'}
          onChange={(event) => {
            setRecipients(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              `${event.target.value || ''}`
                .trim()
                .split(';')
                .map((emailAddress) => emailAddress.trim()),
            );
          }}
          helperMsg={'use ; as separator to have multiple.'}
          {...getErrorProps({ error: errorMap, fieldName: 'recipients' })}
        />
        <Toggle
          label={'Show CC & BCC'}
          onChange={() => setShowCCDiv(!showCCDiv)}
        />
        {getCCDiv()}
        <TextField
          label={'Subject'}
          isRequired
          value={subject}
          onChange={(event) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            setSubject(event.target.value || '');
          }}
          {...getErrorProps({ error: errorMap, fieldName: 'subject' })}
        />
        <RichTextEditor
          label={'Body'}
          isRequired
          value={body}
          inline={true}
          onBlur={(event) => {
            setBody(event.target.getContent());
          }}
          {...getErrorProps({ error: errorMap, fieldName: 'body' })}
        />
        <AttachmentsTable
          entityName={EntityNames.Message}
          entityId={initEntityId}
          types={[AssetTypes.EMAIL_ATTACHMENT]}
          defaultShowingColumns={[]}
          isAssetPublic
          allowDelete
          allowCreate
          selectiveColumnKey={
            `${ComponentName}-${testIdStr}` as SelectiveColKeys
          }
          headerProps={{
            children: <Heading size={'small'}>Attachments</Heading>,
            className: 'no-bottom-margin',
          }}
          onUploaded={(uploaded) => {
            setAttachments(
              _.uniqBy(
                [...attachments, ...uploaded],
                (attachment) => attachment.id,
              ),
            );
          }}
          onDeleted={(deleted) => {
            const deletedIds = deleted.map((deleted) => deleted.id);
            setAttachments(
              attachments.filter(
                (attachment) => deletedIds.indexOf(attachment.id) < 0,
              ),
            );
          }}
        />
      </div>
      <Flex className={'space-top justify-content-end gap-1'}>
        {getFooterWithBtns({
          actionBtnProps: {
            testId: `send-email-btn-${testIdStr}`,
            appearance: 'primary',
            isLoading: isSending,
            iconBefore: Icons.SendIcon,
            btnText: 'Send',
            ...actionBtnProps,
            onClick: () => doSend(),
          },
          cancelBtnProps: {
            testId: `send-email-cancelBtn-${testIdStr}`,
            isLoading: isSending,
            ...cancelBtnProps,
            onClick: () => {
              setResetForm(MathHelper.add(resetForm, 1));
              onCancel && onCancel();
            },
          },
        })}
      </Flex>
    </Wrapper>
  );
};

export default EmailSendingPanel;
