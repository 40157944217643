import SettingsPage from '../../layouts/SettingsPage';
import EmailTemplateList from '../../components/emailTemplate/EmailTemplateList';

const EmailTemplatesPage = () => {
  return (
    <SettingsPage title={'Email Templates'}>
      {(headerProps) => (
        <EmailTemplateList allowDelete allowCreate headerProps={headerProps} />
      )}
    </SettingsPage>
  );
};

export default EmailTemplatesPage;
