import styled from 'styled-components';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import iMessage, {
  MESSAGE_STATUS_FAILED,
  MESSAGE_STATUS_SUCCESS,
  MESSAGE_STATUS_WIP,
} from '../../types/message/iMessage';
import MessageService from '../../services/message/MessageService';
import { iCellParams, iTableColumn } from '../../helpers/DynamicTableHelper';
import ModifyBy from '../common/ModifyBy';
import React from 'react';
import Lozenge from '../frameWork/Lozenge';
import PopupBtn from '../common/PopupBtn';
import Icons from '../frameWork/Icons';
import Button from '../frameWork/Button';
import tokens from '../frameWork/Tokens';
import { SelectiveColKeys } from '../../services/LocalStorageService';

type iMessageList = {
  buildId?: string;
  attributeId?: string;
  testId?: string;
  className?: string;
  selectiveColumnKey?: string;
};
const Wrapper = styled.div``;
const JSONWrapper = styled.div`
  padding: ${tokens('space.200')};
  overflow: auto;
  background-color: ${tokens('color.background.accent.gray.subtlest')};
`;
const MessageList = ({
  testId,
  className,
  buildId,
  attributeId,
  selectiveColumnKey,
}: iMessageList) => {
  const componentName = 'MessageList';
  const testIdStr = `${testId || ''}-${componentName}`;
  const buildIdStr = `${buildId || ''}`.trim();
  const attributeIdStr = `${attributeId || ''}`.trim();

  const { renderDataTable } = useListCrudHook<iMessage>({
    sort: `createdAt:desc`,
    getFn: (params) =>
      MessageService.getAll({
        where: JSON.stringify({
          isActive: true,
          ...(buildIdStr !== '' ? { buildId: buildIdStr } : {}),
          ...(attributeIdStr !== '' ? { attributeId: attributeIdStr } : {}),
          ...(params?.filter || {}),
        }),
        include: 'CreatedBy,UpdatedBy,Build,Attribute',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const getStatusAppearance = (status: string) => {
    switch (status) {
      case MESSAGE_STATUS_SUCCESS: {
        return 'success';
      }
      case MESSAGE_STATUS_FAILED: {
        return 'removed';
      }
      case MESSAGE_STATUS_WIP: {
        return 'inprogress';
      }
      default: {
        return 'default';
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getJSONDiv = (data: any | null, prefix: string) => {
    if (!data) {
      return '';
    }
    return (
      <PopupBtn
        titleId={`${testIdStr}-${data.attributeId || ''}-${data.buildId || ''}-${prefix}`}
        modalProps={(setModelShowing) => ({
          title: prefix.toUpperCase(),
          width: 'large',
          body: (
            <JSONWrapper>
              <pre>{JSON.stringify(data, null, 2)}</pre>
            </JSONWrapper>
          ),
          footer: (
            <Button
              onClick={() => setModelShowing(false)}
              iconBefore={Icons.CrossIcon}
            >
              Close
            </Button>
          ),
        })}
        renderBtn={(onClick) => {
          return (
            <div
              onClick={onClick}
              className="text-xs ellipsis cursor-pointer"
              style={{ maxWidth: '12rem' }}
            >
              {JSON.stringify(data, null, 2)}
            </div>
          );
        }}
      />
    );
  };

  const getColumns = (): iTableColumn<iMessage>[] => [
    {
      key: 'createdAt',
      header: 'Sent By',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iMessage>) => {
        return <ModifyBy by={data?.CreatedBy} at={data?.createdAt} />;
      },
    },
    {
      key: 'status',
      header: 'Status',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iMessage>) => {
        return (
          <Lozenge appearance={getStatusAppearance(data.status || '')}>
            {data.status}
          </Lozenge>
        );
      },
    },
    {
      key: 'request',
      header: 'Request',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iMessage>) => {
        return getJSONDiv(data.request, 'request');
      },
    },
    {
      key: 'response',
      header: 'Response',
      isDefault: false,
      isSelectable: true,
      cell: ({ data }: iCellParams<iMessage>) => {
        return getJSONDiv(data.response, 'response');
      },
    },
    {
      key: 'error',
      header: 'Error',
      isDefault: false,
      isSelectable: true,
      cell: ({ data }: iCellParams<iMessage>) => {
        return getJSONDiv(data.error, 'error');
      },
    },
    {
      key: 'build',
      header: 'Build',
      isDefault: false,
      isSelectable: true,
      cell: ({ data }: iCellParams<iMessage>) => {
        return <div>{data.buildId || ''}</div>;
      },
    },
    {
      key: 'attribute',
      header: 'Attribute',
      isDefault: false,
      isSelectable: true,
      cell: ({ data }: iCellParams<iMessage>) => {
        return <div>{data.attributeId || ''}</div>;
      },
    },
  ];

  return (
    <Wrapper
      className={`${className || ''} ${componentName}`}
      data-testid={testIdStr}
    >
      {renderDataTable({
        columns: getColumns(),
        showPagination: true,
        showPageSizer: true,
        selectiveColumnKey: selectiveColumnKey || SelectiveColKeys.MESSAGE_LIST,
      })}
    </Wrapper>
  );
};

export default MessageList;
