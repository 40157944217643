import { useEffect, useState, ReactNode, useRef, useCallback } from 'react';
import Toaster from './Toaster';
import PageInit from '../../pages/PageInit';
import EmptyState, { iEmptyState } from '../frameWork/EmptyState';
import Button from '../frameWork/Button';
import { useNavigate } from 'react-router-dom';
import { iConfigParams } from '../../services/AppService';

export type iStateBeforeReload = [
  stateBeforeReload: iConfigParams,
  setBeforeReload: (newState: iConfigParams) => void,
];
export type iComponentWithPreloadData<T> = {
  getDataFn: () => Promise<T>;
  children: (props: {
    data: T;
    stateBeforeReload: iStateBeforeReload;
    getDataFn: () => Promise<T>;
    reload: () => Promise<void>; // Add reload function to children props
  }) => ReactNode;
  emptyStateProps?: Omit<iEmptyState, 'header'> & { header?: string };
  forceReload?: number;
  updatedModel?: T | null;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unnecessary-type-constraint
const ComponentWithPreloadData = <T extends any>({
  getDataFn,
  children,
  emptyStateProps,
  updatedModel,
  forceReload = 0,
}: iComponentWithPreloadData<T>) => {
  const [data, setData] = useState<T | undefined | null>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const stateBeforeReload = useRef<iConfigParams>({});
  const navigate = useNavigate();

  const loadData = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await getDataFn();
      setData(resp);
    } catch (err) {
      Toaster.showApiError(err);
    } finally {
      setIsLoading(false);
    }
  }, [getDataFn]);

  useEffect(() => {
    let isCanceled = false;

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const resp = await getDataFn();
        if (!isCanceled) {
          setData(resp);
        }
      } catch (err) {
        if (!isCanceled) {
          Toaster.showApiError(err);
        }
      } finally {
        if (!isCanceled) {
          setIsLoading(false);
        }
      }
    };

    fetchData();
    return () => {
      isCanceled = true;
    };
  }, [forceReload]);

  useEffect(() => {
    if (!updatedModel) {
      return;
    }
    setData(updatedModel);
  }, [updatedModel]);

  const setStateBeforeReload = useCallback(
    (newState: iConfigParams) => (stateBeforeReload.current = newState),
    [],
  );

  if (isLoading || data === undefined) {
    return <PageInit />;
  }

  if (!data) {
    return (
      <EmptyState
        header={`Ops, not found`}
        secondaryAction={
          <Button testId={'back-btn'} onClick={() => navigate(-1)}>
            back
          </Button>
        }
        {...emptyStateProps}
      />
    );
  }

  return (
    <>
      {children({
        data,
        stateBeforeReload: [stateBeforeReload.current, setStateBeforeReload],
        getDataFn,
        reload: loadData,
      })}
    </>
  );
};

export default ComponentWithPreloadData;
