import ServiceHelper from '../helper/ServiceHelper';
import iEmailTemplate from '../../types/email/iEmailTemplate';

const endPoint = '/emailTemplate';
const EmailTemplateService = {
  getAll: ServiceHelper.getPaginatedAll<iEmailTemplate>(endPoint),
  get: ServiceHelper.getById<iEmailTemplate>(endPoint),
  create: ServiceHelper.create<iEmailTemplate>(endPoint),
  update: ServiceHelper.updateById<iEmailTemplate>(endPoint),
  deactivate: ServiceHelper.deactivateById<iEmailTemplate>(endPoint),
};

export default EmailTemplateService;
