import ServiceHelper from '../helper/ServiceHelper';
import iBuildPlan from '../../types/plan/iBuildPlan';

const endPoint = '/buildPlan';
const BuildPlanService = {
  getAll: ServiceHelper.getPaginatedAll<iBuildPlan>(endPoint),
  get: ServiceHelper.getById<iBuildPlan>(endPoint),
  create: ServiceHelper.create<iBuildPlan>(endPoint),
  update: ServiceHelper.updateById<iBuildPlan>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuildPlan>(endPoint),
};

export default BuildPlanService;
