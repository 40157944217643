import Icons from '../../../frameWork/Icons';
import iBuild from '../../../../types/build/iBuild';
import BuildDetailsPlansInitialRequest from './BuildDetailsPlansComponents/BuildDetailsPlansInitialRequest';
import { iMenuItemsMap } from '../../../layout/DetailsPanelWithSubMenuPanel';
import { AttributeForSections } from '../../../../types/attribute/iAttribute';
import EntityNames from '../../../../helpers/EntityNames';
import { AttributeSetCodes } from '../../../../types/attribute/iAttributeSet';
import AttributeSetInputPanel from '../../../attributeSet/AttributeSetInputPanel';
import BuildDetailsPlansChangesWrapper from './BuildDetailsPlansComponents/BuildDetailsPlansChangesWrapper';

export enum BuildDetailsPlansKeys {
  OVERVIEW = 'Initial Request',
  PLAN_CHANGES = 'Plan Changes',
  SOIL_TEST = 'Soil Test',
  Engineering = 'Engineering',
}

type iGetBuildDetailsPlanMenuItemsMap = {
  build: iBuild;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
};

const getMenuItemsMap = ({
  build,
  onBuildUpdated,
  isDisabled = false,
}: iGetBuildDetailsPlanMenuItemsMap): iMenuItemsMap => {
  return {
    [BuildDetailsPlansKeys.OVERVIEW]: {
      name: BuildDetailsPlansKeys.OVERVIEW,
      icon: <Icons.OverviewIcon label={BuildDetailsPlansKeys.OVERVIEW} />,
      component: (
        <BuildDetailsPlansInitialRequest
          entityId={build.id}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsPlansKeys.PLAN_CHANGES]: {
      name: BuildDetailsPlansKeys.PLAN_CHANGES,
      icon: (
        <Icons.EditorNumberListIcon
          label={BuildDetailsPlansKeys.PLAN_CHANGES}
        />
      ),
      component: (
        <BuildDetailsPlansChangesWrapper
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
          allowDelete
          allowEdit
        />
      ),
    },
    [BuildDetailsPlansKeys.SOIL_TEST]: {
      name: BuildDetailsPlansKeys.SOIL_TEST,
      icon: <Icons.JiraLabsIcon label={BuildDetailsPlansKeys.SOIL_TEST} />,
      component: (
        <>
          <AttributeSetInputPanel
            isDisabled={isDisabled}
            entityId={build.id}
            entityName={EntityNames.Build}
            attributeSetCodes={[AttributeSetCodes.SoilTest]}
            attributeSection={AttributeForSections.PLANS}
          />
        </>
      ),
    },
    [BuildDetailsPlansKeys.Engineering]: {
      name: BuildDetailsPlansKeys.Engineering,
      icon: <Icons.ScalesIcon label={BuildDetailsPlansKeys.Engineering} />,
      component: (
        <>
          <AttributeSetInputPanel
            isDisabled={isDisabled}
            entityId={build.id}
            entityName={EntityNames.Build}
            attributeSetCodes={[AttributeSetCodes.Engineering]}
            attributeSection={AttributeForSections.PLANS}
          />
        </>
      ),
    },
  };
};

const BuildDetailsPlansHelper = {
  getMenuItemsMap,
};
export default BuildDetailsPlansHelper;
