import PreloadedAsyncSelector, {
  iPreloadAsyncSelectProps,
} from '../frameWork/PreloadedAsyncSelector';
import { OP_LIKE } from '../../services/ServiceHelper';
import { iOptionWithData } from '../frameWork/Select';
import iEmailTemplate from '../../types/email/iEmailTemplate';
import EmailTemplateService from '../../services/email/EmailTemplateService';

export type iEmailTemplateSelector = iPreloadAsyncSelectProps;
const EmailTemplateSelector = (props: iEmailTemplateSelector) => {
  return (
    <PreloadedAsyncSelector<iEmailTemplate>
      placeholder={'Select an Email Template ...'}
      {...props}
      optionsUniqBy={(data: iEmailTemplate) => data.id}
      formatOptionLabel={(data: iOptionWithData<iEmailTemplate>) => (
        <>
          <div>{data.data.name}</div>
          <small>
            <i>{data.data.description || ' '}</i>
          </small>
        </>
      )}
      getFn={(data) => {
        const { searchText, ...params } = data || {
          currentPage: 1,
          perPage: 10,
        };
        const searchTxtStr = `${searchText || ''}`.trim();
        return EmailTemplateService.getAll({
          ...params,
          sort: 'name:ASC',
          where: JSON.stringify({
            isActive: true,
            ...(searchTxtStr === ''
              ? {}
              : { name: { [OP_LIKE]: `%${searchTxtStr}%` } }),
          }),
        });
      }}
      getValuesFn={(values: string[]) =>
        EmailTemplateService.getAll({
          where: JSON.stringify({ id: values }),
          perPage: values.length,
        })
      }
    />
  );
};

export default EmailTemplateSelector;
