import ServiceHelper from '../helper/ServiceHelper';
import iMessage from '../../types/message/iMessage';
import AppService, { iConfigParams } from '../AppService';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

const endPoint = '/message';

const sendEmail = (
  data: iConfigParams = {},
  config: AxiosRequestConfig = {},
): Promise<iMessage> => {
  return AppService.post(`${endPoint}/sendEmail`, data, config).then(
    ({ data }: AxiosResponse) => data,
  );
};

const MessageService = {
  getAll: ServiceHelper.getPaginatedAll<iMessage>(endPoint),
  get: ServiceHelper.getById<iMessage>(endPoint),
  sendEmail,
};

export default MessageService;
