import { Editor } from '@tinymce/tinymce-react';
import styled from 'styled-components';
import React, { useState } from 'react';
import Toaster from '../common/Toaster';
import iAsset from '../../types/asset/iAsset';
import Spinner from './Spinner';
import FormField, { iFormField } from './FormField';
import tokens from './Tokens';
import Tokens from './Tokens';

type EditorProps = React.ComponentProps<typeof Editor>;

const defaultPlugins = [
  'advlist',
  'lists',
  'autolink',
  'link',
  'image',
  'charmap',
  'preview',
  // 'anchor',
  'searchreplace',
  'visualblocks',
  'code',
  'fullscreen',
  'insertdatetime',
  'media',
  'table',
  'code',
  // 'formatpainter',
];
const defaultToolBars = [
  'undo redo',
  'bold italic underline strikethrough forecolor backcolor',
  'alignleft aligncenter alignright alignjustify',
  'bullist numlist',
  'outdent indent',
  'image media',
  'link',
  'removeformat fullscreen',
];
const Wrapper = styled.div`
  position: relative;
  &.is-invalid {
    border-color: ${tokens('color.border.danger', 'red')} !important;
    border-width: ${tokens('border.width', '2px')} !important;
  }

  &.is-inline {
    border: 1px solid ${Tokens('color.border.accent.gray', 'grey')};
    border-radius: ${tokens('border.radius.100', '4px')};
  }
  .tox-statusbar__branding {
    display: none;
  }

  .loading-mask {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgba(100, 100, 100, 0.65);
    z-index: 999;
    .txt {
      margin: 30% auto;
      display: block;
      color: white;
      width: 4rem;
      text-align: center;
    }
  }

  .mce-content-body {
    min-height: 100px;
    padding: ${tokens('space.100', '8px')} ${tokens('space.075', '6px')};
  }
`;

export type iRichTextEditor = Omit<iFormField<EditorProps>, 'render'> & {
  isInvalid?: boolean;
  testId?: string;
  value?: string;
  className?: string;
  minHeight?: number;
  plugins?: string[];
  toolBar?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  settings?: any;
  onChange?: (text: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEditorChange?: (content: any, editor: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  imagesUploadFn?: (blobInfo: any) => Promise<iAsset>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: (event: any) => void;
};

const RichTextEditor = ({
  value,
  plugins,
  toolBar,
  settings,
  onChange,
  className,
  onEditorChange,
  imagesUploadFn,
  minHeight,
  onBlur,
  ...props
}: iRichTextEditor) => {
  // const editorRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  const getImageUploadSettings = () => {
    if (!imagesUploadFn) {
      return {};
    }
    return {
      // media_dimensions: false, // Disable automatic video size detection
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      images_upload_handler: (blobInfo: any, success: (msg: any) => void) => {
        setIsLoading(true);
        return imagesUploadFn(blobInfo)
          .then((resp) => {
            const imgUrl = `${resp.url || ''}`.trim();
            success(imgUrl);
            return imgUrl;
          })
          .catch((error) => {
            Toaster.showApiError(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      },
    };
  };

  const getIsLoadingDiv = () => {
    if (isLoading !== true) {
      return null;
    }
    return (
      <div className={'loading-mask'}>
        <div className={'txt'}>
          <Spinner />
          <h5>Loading...</h5>
        </div>
      </div>
    );
  };

  return (
    <FormField<EditorProps>
      {...props}
      render={({ testId, ...fProps }) => {
        return (
          <Wrapper
            data-testid={`${testId || ''}-input-wrapper`}
            className={`${className || ''} tinymce-wrapper ${props.isInvalid === true ? 'is-invalid' : ''} ${props.inline === true ? 'is-inline' : ''}`}
            style={
              minHeight
                ? {
                    minHeight: `${minHeight}px`,
                  }
                : {}
            }
          >
            <Editor
              {...fProps}
              initialValue={value || ''}
              apiKey={process.env.REACT_APP_TINYMCE_API_KEY || ''}
              onChange={(editor) =>
                onChange && onChange(editor.target.getContent())
              }
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onEditorChange={(content: any, editor: any) =>
                onEditorChange && onEditorChange(content, editor)
              }
              init={{
                min_height: minHeight,
                menubar: true,
                plugins: plugins || defaultPlugins,
                toolbar: toolBar || defaultToolBars.join('|'),
                removed_menuitems: 'newdocument',
                document_base_url: '',
                relative_urls: false,
                setup: (editor) => {
                  editor.on('init', () => {
                    // Set isLoading to false when TinyMCE is initialized
                    setIsLoading(false);
                  });

                  editor.on('blur', (event) => {
                    onBlur && onBlur(event);
                  });
                },
                ...getImageUploadSettings(),
                ...(settings || {}),
              }}
              // onAddUndo={() => null}
              // onActivate={() => null}
              // onBeforeAddUndo={() => null}
              // onBeforeExecCommand={() => null}
              // onBeforeGetContent={() => null}
              // onBeforePaste={() => null}
              // onBeforeRenderUI={() => null}
            />
            {getIsLoadingDiv()}
          </Wrapper>
        );
      }}
    />
  );
};

export default RichTextEditor;
