import styled from 'styled-components';
import iEmailTemplate from '../../types/email/iEmailTemplate';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '../frameWork/DropdownMenu';
import { IconButton } from '../frameWork/Button';
import Icons from '../frameWork/Icons';
import DeleteConfirmPopupBtn from '../common/DeleteConfirmPopupBtn';
import Lozenge from '../frameWork/Lozenge';
import Toaster, { TOAST_TYPE_SUCCESS } from '../common/Toaster';
import React, { useState } from 'react';
import EmailEditor from '../EmailEditor';
import EmailTemplateService from '../../services/email/EmailTemplateService';
import PopupBtn from '../common/PopupBtn';
import TextField from '../frameWork/TextField';
import { getFooterWithBtns } from '../common/PopupModal';
import { getErrorProps, iErrorMap } from '../form/FormError';

const Wrapper = styled.div``;
export type iEmailTemplateDetailsPanel = iComponentWithPageHeader & {
  emailTemplate: iEmailTemplate;
  allowDelete?: boolean;
  onSaved?: (saved: iEmailTemplate) => void;
  onDeleted?: () => void;
  testId?: string;
  className?: string;
};
const EmailTemplateDetailsPanel = ({
  emailTemplate,
  testId,
  className,
  allowDelete = false,
  onSaved,
  onDeleted,
  headerProps,
  ...props
}: iEmailTemplateDetailsPanel) => {
  const componentName = 'EmailTemplateDetails';
  const testIdStr = `${testId || ''}-${componentName}`;
  const [isSaving, setIsSaving] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showRenamePopup, setShowRenamePopup] = useState(false);
  const [newName, setNewName] = useState(emailTemplate.name);
  const [errors, setErrors] = useState<iErrorMap | null>(null);

  const preCheck = () => {
    const errors: iErrorMap = {};
    if (`${newName || ''}`.trim() === '') {
      errors.name = 'Name is required';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const doRename = () => {
    if (!preCheck()) {
      return;
    }
    setIsSaving(true);
    EmailTemplateService.update(emailTemplate.id, {
      name: newName.trim(),
    })
      .then((saved) => {
        setIsSaving(false);
        onSaved && onSaved(saved);
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showApiError(err);
      });
  };

  const getActions = () => {
    return (
      <>
        <DropdownMenu
          placement={'bottom-end'}
          shouldRenderToParent
          trigger={({ triggerRef, ...props }) => {
            return (
              <IconButton
                {...props}
                icon={Icons.MoreIcon}
                ref={triggerRef}
                label={''}
                testId={`options-btn-${testIdStr}`}
                isSelected={false}
              />
            );
          }}
        >
          <DropdownItemGroup>
            <DropdownItem
              onClick={() => setShowRenamePopup(true)}
              testId={`rename-popup-btn-${testIdStr}`}
            >
              Rename
            </DropdownItem>
            {allowDelete !== true ? null : (
              <DropdownItem onClick={() => setShowDeletePopup(true)}>
                Delete
              </DropdownItem>
            )}
          </DropdownItemGroup>
        </DropdownMenu>
        <PopupBtn
          titleId={`rename-${emailTemplate.id}`}
          renderBtn={() => null}
          forceShown={showRenamePopup}
          modalProps={(setModelShowing) => ({
            title: (
              <>
                Are you about to rename <Lozenge>{emailTemplate.name}</Lozenge>
              </>
            ),
            shouldScrollInViewport: true,
            body: (
              <>
                <TextField
                  isRequired
                  value={newName}
                  label={'New name'}
                  placeholder={'The name of the email template'}
                  testId={`rename-input-${testIdStr}`}
                  onChange={(event) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    setNewName(event.target.value);
                  }}
                  {...getErrorProps({
                    fieldName: 'newName',
                    error: errors,
                  })}
                />
              </>
            ),
            footer: getFooterWithBtns({
              actionBtnProps: {
                testId: `rename-popup-saveBtn-${testId || ''}`,
                isLoading: isSaving,
                iconBefore: Icons.SendIcon,
                isDisabled: `${newName || ''}`.trim() === '',
                btnText: 'Save',
                onClick: doRename,
              },
              cancelBtnProps: {
                testId: `rename-popup-cancelBtn-${testId || ''}`,
                isLoading: isSaving,
                onClick: () => {
                  setNewName(emailTemplate.name);
                  setModelShowing(false);
                  setShowRenamePopup(false);
                },
              },
            }),
          })}
        />
        {allowDelete !== true ? null : (
          <DeleteConfirmPopupBtn
            onCancel={() => {
              setShowDeletePopup(false);
            }}
            forceShown={showDeletePopup}
            testId={`delete-btn-${emailTemplate.id}`}
            titleId={`delete-btn-${emailTemplate.id}`}
            message={
              <>
                You are about to delete
                <Lozenge>{emailTemplate.name}</Lozenge>.
              </>
            }
            deleteFnc={() => EmailTemplateService.deactivate(emailTemplate.id)}
            renderBtn={() => null}
            onDeleted={() => {
              Toaster.showToast(
                <>
                  <b>
                    <u>{emailTemplate.name}</u>
                  </b>
                  {' Deleted'}
                </>,

                TOAST_TYPE_SUCCESS,
              );
              onDeleted && onDeleted();
            }}
          />
        )}
      </>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSave = (data: any, html: string) => {
    setIsSaving(true);
    EmailTemplateService.update(emailTemplate.id, {
      templateData: data,
      html,
    })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <Wrapper
      data-testid={testIdStr}
      className={`${componentName} ${className || ''}`}
    >
      <ComponentWithPageHeader
        {...props}
        headerProps={{
          ...headerProps,
          children: headerProps?.children,
          actions: getActions(),
        }}
      >
        <EmailEditor
          onChanged={(data, html) => handleSave(data, html)}
          designData={emailTemplate.templateData}
          minHeight={`calc(100vh - 12rem)`}
        />
      </ComponentWithPageHeader>
    </Wrapper>
  );
};

export default EmailTemplateDetailsPanel;
