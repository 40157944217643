import ServiceHelper from '../helper/ServiceHelper';
import iBuildPlanChange from '../../types/plan/iBuildPlanChange';

const endPoint = '/buildPlanChange';
const BuildPlanChangeService = {
  getAll: ServiceHelper.getPaginatedAll<iBuildPlanChange>(endPoint),
  get: ServiceHelper.getById<iBuildPlanChange>(endPoint),
  create: ServiceHelper.create<iBuildPlanChange>(endPoint),
  update: ServiceHelper.updateById<iBuildPlanChange>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuildPlanChange>(endPoint),
  nextRevision: ServiceHelper.updateById<iBuildPlanChange>(
    `${endPoint}/nextRevision`,
  ),
};

export default BuildPlanChangeService;
