import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  &.space-top {
    margin-top: 1rem;
  }
`;

export const InlineFlex = styled.div`
  display: inline-flex;
`;

export default Flex;
